import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'antd';
import AppAnimateGroup from '../../@crema/core/AppAnimateGroup';
import './AuthWrapper.style.less';
import './image.style.less';
import {AppInfoView} from '../../@crema';
import AppLogoAuth from "../../@crema/core/AppLayout/components/AppLogoAuth";

const AuthSignInWrapper = ({children}) => {
  return (
    <AppAnimateGroup
      type='scale'
      animateStyle={{flex: 1}}
      delay={0}
      interval={10}
      duration={200}>
      <div className='auth-wrap' key={'wrap'}>
        <Card className='auth-card'>
          <div className='auth-main-content'>
            <div className='auth-card-header'>
              <AppLogoAuth />
            </div>
            {children}
          </div>
          <div className='auth-wel-action signin-right'>
            <div className='auth-wel-content'>
              <h2>Welcome to Democratrade!</h2>
              <p>
                Democratrade is a platform for realizing your trading potential.
              </p>
            </div>
          </div>
        </Card>
      </div>
      <AppInfoView />
    </AppAnimateGroup>
  );
};

export default AuthSignInWrapper;

AuthSignInWrapper.propTypes = {
  children: PropTypes.node,
};
